import axios from 'axios'
import store from '@/store'
import Vue from 'vue'
import router from '@/router'
import { getAccessToken, removeAll } from '@/utils/auth'

const instance = axios.create({
  // 域名
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8'
  },
  // 5 分钟超时
  timeout: 300000,
  // 允许携带 cookie
  withCredentials: true
})

// 并发请求
Vue.prototype.$http = axios
// 在全局请求和响应拦截器中添加请求状态
const loading = null

// 请求拦截器
instance.interceptors.request.use(config => {
  // const token = store.getters.token
  const token = getAccessToken()
  if (token) {
    // 在请求的 Authorization 首部添加 token
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (loading) {
      loading.close()
    }

    const code = response.status
    if ((code >= 200 && code < 300) || code === 304) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (loading) {
      loading.close()
    }

    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登陆页面
          console.log('401 错误')

          Vue.$cookies.remove('token')
          store.commit('USER_LOGOUT')
          store.commit('delToken')
          removeAll()

          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
          break
        case 404:
          console.log('404 错误')
          break
        default:
          console.log(error.message)
      }
    } else {
      // 请求超时或者网络有问题
      if (error.message.includes('timeout')) {
        console.log(error.message)
      } else {
        console.log(error.message)
      }
    }
    return Promise.reject(error)
  }
)

// HTTP 请求
export function post(url, data) {
  return instance({
    method: 'post',
    url,
    data: JSON.stringify(data)
  })
}

export function postForm(url, formData) {
  return instance({
    method: 'post',
    url,
    data: formData
  })
}

export function put(url, data) {
  return instance({
    method: 'put',
    url,
    data: JSON.stringify(data)
  })
}

export function delete0(url, data) {
  return instance({
    method: 'delete',
    url,
    data: JSON.stringify(data)
  })
}

export function get(url, params) {
  return instance({
    method: 'get',
    url,
    params
  })
}
